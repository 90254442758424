import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useState, forwardRef, useEffect, useCallback } from 'react';

import {
    Box,
    Grid,
    Typography,
    Divider,
    TextField,
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    CircularProgress,
    Zoom
} from '@mui/material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DetailsIcon from '@mui/icons-material/Details';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import axios from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import useRefMounted from 'src/hooks/useRefMounted';
import { TextEditor } from 'src/components/TextEditor';
import { setLimit, setPage, updateEvent } from 'src/slices/eventSlice';
import SaveIcon from '@mui/icons-material/Save';
import Text from 'src/components/Text';

const Input = styled('input')({
    display: 'none'
});

const FormEvent = ({ data, titleButtonAction, isEdit, messageOK = 'The event was created successfully', showButtonAttachment = true }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useRefMounted();

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubCategories] = useState([]);
    const [classifications, setClassifications] = useState([]);

    const [category, setCategory] = useState(0);
    const [subcategory, setSubcategory] = useState(0);

    const isClosed = data.statusCode == 'CERRADO' ? true : false;

    const getCategories = useCallback(async () => {
        try {
            const response = await axios.get('/api/ticket/category');

            if (isMountedRef.current) {
                setCategories(response.data.payload);
            }

        } catch (err) {
            console.error('listCategoriesException', err);
        }
    }, [isMountedRef])

    const getSubCategories = async () => {
        try {
            const response = await axios.get('/api/ticket/category/' + category + '/subcategories');

            setSubCategories(response.data.payload);

        } catch (err) {
            console.error('listSubCategoriesException', err);
        }
    }

    const getClassifications = async () => {
        try {
            const response = await axios.get('/api/ticket/category/subcategories/' + subcategory + '/classifications');

            setClassifications(response.data.payload);

        } catch (err) {
            console.error('listClassificationsException', err);
        }
    }

    const createAttachment = async (ticketId: number | string, file: any) => {
        let wasCreated = false;

        try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await axios.post(`/api/ticket/${ticketId}/attachment`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });

            if (response.status == 200) {
                wasCreated = true;
                handleCreateEventMessages('The file was uploaded successfully', 'success');
            }
        } catch (error) {
            console.error('file-attachment', error);
            handleCreateEventMessages('An error occurred while attaching the file. Please contact the administrator' + '. ' + 'Code: ' + error.status, 'error');
        }

        return wasCreated;
    }

    const createEvent = async (data) => {
        try {
            const response = await axios.post('/api/ticket', {
                "description": data.description,
                "kilometer": data.kilometer,
                "sector": data.sector,
                "commune": data.commune,
                "orientation": data.orientation,
                "dateEvent": data.dateEvent,
                "channelCode": data.channelCode,
                "classificationId": data.classificationId,
                "realAddress": data.realAddress,
                "email": data.email,
                "fullName": data.fullName,
                "phone": data.phone,
                "rut": data.rut,
                "patente": data.patente,
            });


            if (response.status == 200) {

                if (response.data.hasOwnProperty('payload')) {
                    if (data.file == null) {
                        handleCreateEventMessages(messageOK, 'success')
                    } else {

                        let wasCreatedAttachment = await createAttachment(response.data.payload, data.file);

                        if (wasCreatedAttachment) {
                            handleCreateEventMessages(messageOK, 'success')
                        } else {
                            handleCreateEventMessages('the event was created, but the attachment could not be loaded. If you want to try again, edit the event and upload the attachment', 'warning')
                        }
                    }
                } else if (response.data.hasOwnProperty('errors')) {
                    handleCreateEventMessages("Ha ocurrido un error al crear el evento: " + response.data.errors, 'error')
                }
            }
        } catch(error) {
            handleCreateEventMessages("Ha ocurrido un error al crear el evento: " + error, 'error')
        }

    }

    const editEvent = async (data: any) => {
        const response = await updateEvent(data);

        if (response.status == 200 && response.data.payload !== null) {
            handleCreateEventMessages('The event was updated successfully', 'success');
        }
    }

    const closedTicket = async (id, body) => {

        body.statusCode = 'CERRADO';

        const res = await updateEvent(body);

        if (res) {
            handleCreateEventMessages('The ticket was closed successfully', 'success')
        } else {
            handleCreateEventMessages('The ticket was not closed', 'error')
        }
    }

    const handleCreateEventMessages = (message: string, variant: any) => {
        enqueueSnackbar(t(message), {
            variant: variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            TransitionComponent: Zoom
        });
    };

    useEffect(() => {
        getCategories();

        if (isEdit) {
            setCategory(data.category)
        }
    }, [getCategories])

    useEffect(() => {

        if (category !== 0) {
            setSubCategories([]);
            setClassifications([]);
            getSubCategories();
        }

        if (isEdit) {
            setSubcategory(data.subcategory)
        }
    }, [category])

    useEffect(() => {

        if (subcategory !== 0) {
            setClassifications([]);
            getClassifications();
        }

    }, [subcategory])

    if (categories.length !== 0) {
        return (
            <Formik
                initialValues={{
                    id: data.id,
                    description: data.description,
                    kilometer: data.kilometer,
                    sector: data.sector,
                    commune: data.commune,
                    orientation: data.orientation,
                    channelCode: 'WEB_SELF_SERVICE',
                    classificationId: data.classificationId,
                    dateEvent: data.dateEvent,
                    assignedToId: data.assignedToId,
                    submit: null,
                    category: data.category,
                    subcategory: data.subcategory,
                    statusCode: data.statusCode,
                    file: null,
                    realAddress: data.realAddress === undefined ? '' : data.realAddress,
                    realAddress1: data.realAddress === undefined ? '' : data.realAddress,
                    email: data.email === undefined ? '' : data.email,
                    fullName: data.fullName,
                    phone: data.phone,
                    rut: data.rut,
                    patente:data.patente
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().max(50,'Máximo 50 caracteres').email('Debe colocar un correo válido').required(t('The email field is required')),
                    realAddress1: Yup.string().matches(/^[0-9A-Za-z\-\.\,\s]+$/, 'Solo se permiten caracteres alfanumericos con guiones, y espacios en blanco'),
                    kilometer: Yup.number().min(410, 'El kilometro minimo debe ser igual o mayor que 410').max(420, 'El kilometro maximo es hasta 420')
                        .required(t('The kilometer field is required')),
                    phone: Yup.string().max(50,'Máximo 50 caracteres').matches(/^[0-9]+$/, 'Solo se permiten caracteres númericos')
                        .required(t('The phone field is required')),
                    sector: Yup.string().max(20,'Máximo 20 caracteres').matches(/^[a-zA-Z\s]+$/, 'Solo se permiten caracteres alfanumericos y espacios en blanco')
                        .required(t('The sector field is required')),
                    commune: Yup.string().max(20,'Máximo 20 caracteres').matches(/^[a-zA-Z\s]+$/, 'Solo se permiten caracteres alfanumericos y espacios en blanco')
                        .required(t('The comune field is required')),
                    fullName: Yup.string().max(100, 'La longitud maxima es de 100 caracteres').matches(/^[a-zA-Z\s]+$/, 'Solo se permiten caracteres alfanumericos y espacios en blanco')
                        .required(t('The fullname field is required')),
                    rut: Yup.string().max(50,'Máximo 50 caracteres').matches(/^[0-9A-Za-z\-\.]+$/, 'Solo se permiten caracteres alfanumericos con guiones')
                        .required(t('The RUT field is required')),
                    description: Yup.string().max(300, 'Solo se permiten mensajes hasta 300 caracteres')
                        .required(t('The description field is required')),
                    dateEvent: Yup.string()
                        .required(t('The date field is required')),
                    realAddress: Yup.string().max(100,'Máximo 100 caracteres')
                        .when(['email', 'realAddress1'], {
                            is: (email: string, realAddress1: string) => !email && !realAddress1,
                            then: Yup.string().required(t('The email physic field is required'))
                        }).matches(/^[0-9A-Za-z\-\.\,\s]+$/, 'Solo se permiten caracteres alfanumericos con guiones, y espacios en blanco'),
                    category: Yup.string().required(t('The purpose event is required')),
                    orientation: Yup.string().required('La orientación es requerida'),
                    subcategory: Yup.string().required(t('El tipo de evento es requerido')),
                    classificationId: Yup.string().required(t('La clasificación es requerida')),
                }, [['email', 'realAddress1']])
                }
                onSubmit={
                    async (
                        _values,
                        { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                        try {
                            if (isEdit) {
                                await editEvent(_values);
                            } else {
                                await createEvent(_values);
                            }

                            resetForm();
                            setStatus({ success: true });
                            setSubmitting(true);
                        } catch (err) {
                            console.error('exception-event-driver', err);
                            setStatus({ success: false });
                            setErrors({ submit: err.data.error });
                            setSubmitting(false);
                            handleCreateEventMessages(err.data.error, 'error');
                        }
                    }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit} style={{ padding: '5%' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    <AccountBoxIcon /> {t('Personal Information')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t('Full Name')}
                                    name={'fullName'}
                                    size='small'
                                    disabled={isClosed}
                                    placeholder='Max. 100 caracteres'
                                    error={Boolean(touched.fullName && errors.fullName)}
                                    helperText={touched.fullName && errors.fullName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fullName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t('RUT')}
                                    name={'rut'}
                                    size='small'
                                    disabled={isClosed}
                                    error={Boolean(touched.rut && errors.rut)}
                                    helperText={touched.rut && errors.rut}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.rut}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t('Email')}
                                    name={'email'}
                                    size='small'
                                    disabled={isClosed}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="phone-required"
                                    label={t('Phone')}
                                    name={'phone'}
                                    size='small'
                                    disabled={isClosed}
                                    error={Boolean(touched.phone && errors.phone)}
                                    helperText={touched.phone && errors.phone}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="label-purpose-event">{t('Purpose Event')}</InputLabel>
                                    <Select
                                        labelId="label-purpose-event"
                                        id="purpose-event-select"
                                        label={t('Purpose event')}
                                        name={"category"}
                                        disabled={isClosed}
                                        value={values.category}
                                        error={Boolean(touched.category && errors.category)}
                                        onBlur={handleBlur}
                                        onChange={(newValue) => {
                                            setCategory(newValue.target.value);
                                            setFieldValue('category', newValue.target.value);
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>{t('Please select one')}</em>
                                        </MenuItem>
                                        {
                                            categories.map((category) => {
                                                return (
                                                    <MenuItem key={category.id} value={category.code}>{t(category.name)}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{t('Purpose event select')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ mt: 3, width: '100%' }}>
                                    <InputLabel id="label-type-event">{t('Type Event')}</InputLabel>
                                    <Select
                                        labelId="label-type-event"
                                        id="type-event-select"
                                        label={t('Type event')}
                                        name={"subcategory"}
                                        disabled={isClosed}
                                        value={values.subcategory}
                                        error={Boolean(touched.subcategory && errors.subcategory)}
                                        onBlur={handleBlur}
                                        onChange={(newValue) => {
                                            setSubcategory(newValue.target.value);
                                            setFieldValue('subcategory', newValue.target.value);
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>{t('Please select one')}</em>
                                        </MenuItem>
                                        {
                                            subcategories.map((subcategory) => {
                                                return (
                                                    <MenuItem key={subcategory.id} value={subcategory.id}>{t(subcategory.name)}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{t('Please select one type')}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ mt: 1, width: '100%' }}>
                                    <InputLabel id="label-classification-event">{t('Classification')}</InputLabel>
                                    <Select
                                        labelId="label-classification-event"
                                        id="classification-event-select"
                                        name={"classificationId"}
                                        disabled={isClosed}
                                        value={values.classificationId}
                                        error={Boolean(touched.classificationId && errors.classificationId)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label={t('Classification')}
                                    >
                                        <MenuItem value="">
                                            <em>{t('Please select one')}</em>
                                        </MenuItem>
                                        {
                                            classifications.map((classification) => {
                                                return (
                                                    <MenuItem key={classification.id} value={classification.id}>{t(classification.name)}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{touched.classificationId && errors.classificationId}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="label-orientation-event">{t('Orientation')}</InputLabel>
                                    <Select
                                        labelId="label-orientation-event"
                                        id="orientation-event-select"
                                        name={"orientation"}
                                        disabled={isClosed}
                                        value={values.orientation}
                                        error={Boolean(touched.orientation && errors.orientation)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label={t('Orientation')}
                                    >
                                        <MenuItem value="">
                                            <em>{t('Please select one')}</em>
                                        </MenuItem>
                                        <MenuItem value={"NORTH"}>{t('North to South')}</MenuItem>
                                        <MenuItem value={"SOUTH"}>{t('South to North')}</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.orientation && errors.orientation}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="correo-fisico-required"
                                    label={t('Email Physical')}
                                    name={"realAddress"}
                                    disabled={isClosed}
                                    fullWidth
                                    size='small'
                                    error={Boolean(touched.realAddress && errors.realAddress)}
                                    helperText={touched.realAddress && errors.realAddress}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.realAddress}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="patente-required"
                                    label={t('Patente')}
                                    name={"patente"}
                                    disabled={isClosed}
                                    fullWidth
                                    size='small'
                                    error={Boolean(touched.patente && errors.patente)}
                                    helperText={touched.patente && errors.patente}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.patente}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    <DetailsIcon /> <b>{t('Detail Event')}</b>
                                </Typography>
                            </Grid>

                            <br />
                            <Grid item xs={6}>

                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <TextField
                                        id="sector-event"
                                        name={"sector"}
                                        value={values.sector}
                                        disabled={isClosed}
                                        error={Boolean(touched.sector && errors.sector)}
                                        helperText={touched.sector && errors.sector}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label={t('Sector')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>

                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <TextField
                                        id="commune-event"
                                        name={"commune"}
                                        disabled={isClosed}
                                        value={values.commune}
                                        error={Boolean(touched.commune && errors.commune)}
                                        helperText={touched.commune && errors.commune}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label={t('Commune')}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <TextField
                                        id="km-event"
                                        name={"kilometer"}
                                        type="number"
                                        value={values.kilometer}
                                        disabled={isClosed}
                                        placeholder='410-420'
                                        error={Boolean(touched.kilometer && errors.kilometer)}
                                        helperText={touched.kilometer && errors.kilometer}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label={t('Kilometer')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            label={t('Date Event')}
                                            value={values.dateEvent}
                                            disabled={isClosed || isEdit}
                                            onChange={(newValue) => {
                                                setFieldValue('dateEvent', newValue);
                                            }}
                                            maxDateTime={new Date()}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                                <Divider />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">
                                    <DescriptionIcon /> <b>{t('Description of event')}</b>
                                </Typography>
                            </Grid>
                            {showButtonAttachment ? <Grid item xs={6}>
                                <label htmlFor="contained-button-file">
                                    <Input
                                        id="contained-button-file"
                                        name={'file'}
                                        type="file"
                                        onChange={async (e) => {
                                            setFieldValue('file', e.target.files[0]);
                                        }}
                                    />
                                    <Button variant="contained" component="span">
                                        {t('Add a photo or file')} <AttachFileIcon />
                                    </Button>
                                </label>
                            </Grid> : null}

                            <Grid item xs={12}>
                                <TextEditor
                                    disabled={isClosed}
                                    setFieldValue={(val) => setFieldValue("description", val)}
                                    value={values.description}
                                />
                                {
                                    errors.description && touched.description ? (<Text color="error">{errors.description}</Text>) : null
                                }
                            </Grid>
                        </Grid>
                        <br />

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                        >
                            <Grid item xs={12}>

                                <Button
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : <SaveIcon fontSize="small" />
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting || isClosed}
                                    type='submit'
                                    variant='contained'
                                    sx={{ float: 'right' }}
                                >{t(titleButtonAction)}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    isEdit ? <Button
                                        startIcon={
                                            isSubmitting ? <CircularProgress size="1rem" /> : <AddTwoToneIcon fontSize="small" />
                                        }
                                        color='error'
                                        disabled={isSubmitting}
                                        type='button'
                                        variant='contained'
                                        onClick={async () => {
                                            await closedTicket(values.id, data);
                                        }}
                                    >{t('Close this event')}</Button> : null
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                        >

                        </Grid>
                    </form>
                )}
            </Formik >
        );
    } else {
        return (
            <>
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        height: '100%'
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress size={64} disableShrink thickness={3} />
                </Box>
            </>
        )
    }
}

export default FormEvent;